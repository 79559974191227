import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './assets/resource/js/axios/axiosInterceptor';
import MasonryWall from '@yeger/vue-masonry-wall';
import globalMethods from './assets/resource/js/global/global';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { Quasar } from 'quasar';

if(window.Kakao){
    window.Kakao.init("405e50f67ff556143e0939597260b510"); 
}

// 병원코드, 다국어, 병원No store
store.commit("getHost", location.hostname.split('.')[0]);

var hpCd = 'MC';
store.commit("getHpCd", hpCd);
store.commit("getLang", location.pathname.split('/')[1]);

var instNo = '4';
store.commit("getInstNo", instNo);

var app = createApp(App);
app.config.globalProperties.axios = axios;
app.config.globalProperties.menuList = [];
app.config.globalProperties.headerMenuList = [];

//'N'
var sync = 'N';

/**
 * 메뉴목록 조회하기
 */
axios.get('/menu.do', {
        params: {
            instNo: store.state.hpInfoStore.instNo,
            lang: store.state.hpInfoStore.lang,
            rand: Math.random()
        }
    })
    .then((res) => {
        app.config.globalProperties.menuList = res.data;
        app.config.globalProperties.headerMenuList = res.data;
        sync = 'Y';
    })
    .catch(err => console.log(err));


var interval = setInterval(function(){
    if(sync == 'Y'){
        app.use(store).use(router).use(MasonryWall).use(globalMethods).use(Datepicker).use(Quasar).mount('#app');
        clearInterval(interval);
    }
},100);
