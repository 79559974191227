import store from "@/store";
import router from ".";
import axios from 'axios';

// 로그인 사용자 밀어내기
function isLoginCheck(next, text) {
    if (store.state.loginTypeStore.status == true) {
        //alert(`로그인이 되어있습니다.\n${text}을(를) 하려면 로그아웃을 해주시길 바랍니다.`);
        router.replace('/kr/index.do');
    } else {
        return next();
    }
}

// 비로그인 사용자 밀어내기
function isNotLoginCheck(to, from, next) {
    axios.get(store.state.kumcApi.myPageApi.myInfo).then(response => {
        if (response.data == '') {
            alert('로그인 후 이용이 가능합니다.');
            store.commit('getLoginStatus', false);
            store.commit("getRedirectUrl", to.href);
            router.push('/kr/login.do');
        } else {
            return next();
        }
    });
}

// 외부 로그인 사용자 밀어내기
function isNotSSOLoginCheck(to, from, next) {
    axios.get(store.state.kumcApi.myPageApi.myInfo).then(response => {
        if (response.data == '') {
            alert('로그인 후 이용이 가능합니다.');
            store.commit('getLoginStatus', false);
            store.commit("getRedirectUrl", to.href);
            router.push('/kr/login.do');
        } else if (response.data.memType != 'S') {
            alert('학생/동문, 교직원 회원만 이용 가능합니다.');
            router.push('/kr/index.do');
        } else {
            return next();
        }
    });
}

// 내부 로그인 회원 학생 / 교직원 구분
function isNotSSOAndStaffLoginCheck(to, from, next) {
    axios.get(store.state.kumcApi.myPageApi.myInfo).then(response => {
        if (response.data == '') {
            alert('로그인 후 이용이 가능합니다.');
            store.commit('getLoginStatus', false);
            store.commit("getRedirectUrl", to.href);
            router.push('/kr/login.do');
        } else if (response.data.memType != 'S') {
            alert('학생/동문, 교직원 회원만 이용 가능합니다.');
            router.push('/kr/index.do');
        } else {
            // 교직원 : S1, P1, EC, F9
            var staff = ['S1', 'P1', 'EC', 'F9'];
            // 교직원/학생
            if(to.query.category == undefined || to.query.category == '학생교육 동영상'){
                next();
            }
            // 교직원
            else{
                if(staff.includes(response.data.ssoGid)){
                    next();
                }else{
                    alert('교직원 회원만 이용 가능합니다.');
                    router.push(from.fullPath);
                }
            }
        }
    });
}


export {isLoginCheck, isNotLoginCheck, isNotSSOLoginCheck, isNotSSOAndStaffLoginCheck};