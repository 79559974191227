/**
 * 예약관련 Store
 * - 초기화는 어느 시점에 할것인가.
 */
const reserveStore = {
    state: {
        item : '',
        date : '',      //통계는 금토일 안됨.(월간/주간달력 처리해야함.)
        eqSn : '',      //공간/장비/통계 SEQ
        lgrpCd : '',    //hall, consult, equip
        mgrpCd : '',    //중분류
        sgrpCd : '',    //소분류    
        groupList : [], //중분류 목록
        eqList : []     //소분류 목록
    },
    mutations: {
       
    }
};

export default reserveStore;