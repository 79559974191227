import store from "@/store";

const loginTypeStore = {
    state: {
        status: false,
        ssoMember : false   //SSO 로그인 회원 여부
    },
    mutations: {
        getLoginStatus: function (state, status) {
            state.status = status;
        },
        getSSOMember: function (state, status) {
            state.ssoMember = status;
        }
    },
    actions: {
        // 로그인 이후 일정 시간 지나면 status 값 변경
        setLoginStatus({commit}, status){
            if(store.state.loginTypeStore.status == true){
                commit('getLoginStatus', status);
                setTimeout(() => {
                    commit('getLoginStatus', false);
                    console.log('true -> false');
                }, 1790000);
            }
        }
    }
};

export default loginTypeStore;